import {
  Contact,
  Education,
  Header,
  Home,
  Projects,
  Services,
  Footer,
} from "./components";

import AnimatedBackground from "./components/AnimatedBackground";

function App() {
  return (
    <div className="relative min-h-screen text-white">
    <AnimatedBackground />
    <div className="relative z-10">
      <Header />
      <Home />
      <Education />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </div>
  </div>
  
  );
}

export default App;

