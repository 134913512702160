export const Services = () => {
  const servicesData = [
    {
      title: "Web Development",
      description:
        "Building modern, responsive websites using cutting-edge technologies such as HTML, CSS, JavaScript, React, and Tailwind CSS. We focus on delivering fast, scalable, and SEO-friendly web applications.",
      icon: "🌐",
    },
    {
      title: "UI/UX Design",
      description:
        "Creating intuitive and visually appealing user interfaces that ensure a seamless user experience. We use design tools like Figma and Adobe XD to bring ideas to life with user-centered design principles.",
      icon: "🎨",
    },
    {
      title: "SEO Optimization",
      description:
        "Enhancing your website's visibility on search engines through advanced SEO techniques. We ensure your content is easily discoverable, leading to higher organic traffic and better search rankings.",
      icon: "🚀",
    },
    {
      title: "Backend Development",
      description:
        "Building robust and scalable backend systems using Java, Spring Boot, and Hibernate. We specialize in developing RESTful APIs, database management, and secure, high-performance applications.",
      icon: "⚙️",
    },
  ];

  return (
    <section
      id="services"
      className=" text-white py-20 scroll-margin-top-24"
    >
      <div className="container mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-extrabold mb-16 text-center tracking-wide">
          🛠️ Services
        </h2>

        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-4">
          {servicesData.map((service, index) => (
            <div
              key={index}
              className="relative group bg-white/5 backdrop-blur-lg border border-teal-500 text-white p-8 rounded-2xl shadow-lg transition-all hover:scale-105 hover:shadow-teal-500/40 text-center"
            >
              <div className="flex justify-center mb-6">
                <div className="text-4xl bg-teal-500/20 backdrop-blur-md rounded-full p-4 w-16 h-16 flex items-center justify-center shadow-inner">
                  {service.icon}
                </div>
              </div>
              <h3 className="text-xl font-bold mb-3 group-hover:text-teal-400 transition-colors">
                {service.title}
              </h3>
              <p className="text-sm text-gray-300 leading-relaxed">
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
