const AnimatedBackground = () => {
    return (
      <div className="fixed inset-0 -z-10 bg-black overflow-hidden">
        {/* Pokretni SVG hex grid */}
        <div className="absolute w-[200%] h-[200%] -top-1/2 -left-1/2 animate-slowMove">
          <svg
            className="w-full h-full opacity-20"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
            viewBox="0 0 100 100"
            fill="none"
          >
            <defs>
              <pattern
                id="hex"
                width="10"
                height="17.32"
                patternUnits="userSpaceOnUse"
                patternTransform="scale(2)"
              >
                <polygon
                  points="5,0 10,2.5 10,7.5 5,10 0,7.5 0,2.5"
                  fill="#14b8a6"
                  fillOpacity="0.2"
                />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#hex)" />
          </svg>
        </div>
  
        {/* Glow efekat iza */}
        <div className="absolute top-1/3 left-1/3 w-[400px] h-[400px] bg-teal-500 opacity-20 rounded-full blur-3xl animate-pulseSlow" />
      </div>
    );
  };
  
  export default AnimatedBackground;
  