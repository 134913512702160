import { useState, useEffect } from "react";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("#home");

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setMenuOpen(false);
  };

  return (
    <>
      <div className="pt-16"></div>

      <header className="fixed top-0 left-0 w-full z-50 border-b border-white/10 bg-black/60 backdrop-blur-lg shadow-xl">
        <nav className="container mx-auto flex justify-between items-center py-4 px-6">
          <a href="/" className="flex items-center group relative">
            <span className="text-2xl font-extrabold text-white group-hover:text-teal-400 transition-colors duration-300">
              Milan{" "}
              <span className="text-teal-400 relative">
                Govedarica
                <span className="absolute inset-0 rounded-full bg-teal-400 opacity-20 blur-lg scale-110"></span>
              </span>
            </span>
          </a>

          {/* DESKTOP MENU */}
          <div className="hidden md:flex items-center space-x-8">
            {["#home", "#education", "#services", "#projects", "#contact"].map(
              (section) => (
                <a
                  key={section}
                  href={section}
                  className={`relative text-lg text-white hover:text-teal-400 transition-colors duration-300 ${
                    activeSection === section ? "font-bold text-teal-400" : ""
                  }`}
                  onClick={() => handleSectionClick(section)}
                >
                  <span className="relative group">
                    {section.replace("#", "").charAt(0).toUpperCase() +
                      section.slice(2)}
                    <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-teal-400 transition-all duration-300 group-hover:w-full"></span>
                  </span>
                </a>
              )
            )}
          </div>

          {/* MOBILE MENU TOGGLE */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-white focus:outline-none"
            >
              {menuOpen ? (
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>
        </nav>

        {/* MOBILE MENU */}
        {menuOpen && (
          <div className="md:hidden bg-black/80 backdrop-blur-md px-6 py-4 space-y-3 animate-fade-in-down">
            {[
              "#home",
              "#education",
              "#services",
              "#projects",
              "#testimonials",
              "#contact",
            ].map((section) => (
              <a
                key={section}
                href={section}
                className="block text-lg text-white hover:text-teal-400 transition-colors duration-300"
                onClick={() => handleSectionClick(section)}
              >
                {section.replace("#", "").charAt(0).toUpperCase() +
                  section.slice(2)}
              </a>
            ))}
          </div>
        )}
      </header>
    </>
  );
};
