import project3Img from "../../assets/project3.jpg";
import aboutImage3 from "../../assets/aboutImage3.jpg";

export const Projects = () => {
  const projectsData = [
    {
      title: "E-Commerce Application Demo",
      description:
        "A fully functional full-stack e-commerce application built from scratch, featuring a modern frontend, secure backend, and integrated payment system.",
      link: "https://main-ebookbookcode.netlify.app/",
      image: project3Img,
    },
    {
      title: "Beauty Salon Web Application",
      description:
        "The Beauty salon application offers a fully interactive experience for users looking to schedule appointments and learn about salon services.",
      link: "https://beautywandolly.netlify.app/",
      image: aboutImage3,
    },
  ];

  return (
    <section
      id="projects"
      className=" text-white py-20 scroll-margin-top-24"
    >
      <div className="container mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-extrabold mb-16 text-center tracking-wide">
          🚀 Projects
        </h2>

        <div
          className={`grid gap-10 ${
            projectsData.length === 1
              ? "grid-cols-1 place-items-center"
              : "grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
          }`}
        >
          {projectsData.map((project, index) => (
            <a
              key={index}
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="relative bg-white/5 backdrop-blur-md p-6 rounded-2xl shadow-xl border border-teal-600 transition-all hover:scale-[1.03] hover:shadow-teal-500/50 max-w-md w-full group"
            >
              {/* Badge */}
              <span className="absolute top-3 right-3 bg-teal-500 text-xs font-bold text-black px-3 py-1 rounded-full opacity-90 group-hover:scale-105 transition-transform">
                Live 
              </span>

              <img
                src={project.image}
                alt={project.title}
                className="rounded-xl mb-4 w-full h-52 object-cover border border-teal-700"
                loading="lazy"
              />
              <h3 className="text-2xl font-semibold mb-2 group-hover:text-teal-400 transition-colors duration-300">
                {project.title}
              </h3>
              <p className="text-gray-300 leading-relaxed text-sm">
                {project.description}
              </p>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};
