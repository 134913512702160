import { useState } from "react";

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!formData.message) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    fetch("https://secure-shore-01208-e8af61b136af.herokuapp.com/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true);
          setFormData({ name: "", email: "", message: "" });
          setTimeout(() => setSubmitted(false), 2000);
        } else {
          console.error("Error submitting the form");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <section
      id="contact"
      className=" text-white py-24 scroll-margin-top-24"
    >
      <div className="container mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-extrabold mb-16 text-center">
          ✉️ Contact Me
        </h2>

        <div className="flex justify-center">
          <div className="w-full max-w-xl bg-white/5 backdrop-blur-md border border-teal-500 rounded-2xl shadow-xl p-8 text-white">
            <h3 className="text-2xl font-bold mb-3 text-center">
              Let's work together!
            </h3>
            <p className="text-gray-300 text-center mb-6">
              Have a project in mind or just want to say hi?
            </p>

            {submitted && (
              <div className="bg-teal-500 text-black text-sm font-semibold px-4 py-3 rounded-xl mb-6 text-center animate-pulse">
                ✅ Message sent successfully!
              </div>
            )}

            <form className="space-y-5" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-200 mb-1"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 bg-gray-800 rounded-xl border border-gray-600 focus:outline-none focus:ring-2 focus:ring-teal-400"
                />
                {errors.name && (
                  <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-200 mb-1"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-3 bg-gray-800 rounded-xl border border-gray-600 focus:outline-none focus:ring-2 focus:ring-teal-400"
                />
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                )}
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-200 mb-1"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-4 py-3 bg-gray-800 rounded-xl border border-gray-600 focus:outline-none focus:ring-2 focus:ring-teal-400"
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.message}
                  </p>
                )}
              </div>

              <button
                type="submit"
                className="w-full px-4 py-3 bg-teal-400 text-black font-bold rounded-full hover:bg-teal-300 transition duration-300 text-lg flex items-center justify-center gap-2"
              >
                📩 Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
