export const Education = () => {
  const educationData = [
    {
      year: "2022",
      title: "IT Academy - Java Backend Course",
      description:
        "Completed a comprehensive Java backend development course at IT Academy, covering Java, Hibernate, and RESTful web services.",
    },
    {
      year: "2023",
      title: "Master Spring, Spring Boot, React & JavaScript (in28minutes)",
      description:
        "Participated in an advanced masterclass by in28minutes, covering Spring, Spring Boot, React, and modern JavaScript, focusing on building full-stack web applications.",
    },
    {
      year: "2024",
      title: "Bachelor's Degree in Information Technology",
      description:
        "Graduated from Singidunum University with a degree in Information Technology, focusing on software engineering, databases, and web development.",
    },
  ];

  return (
    <section id="education" className=" text-white py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-extrabold mb-16 text-center tracking-wide">
          🎓 Education & Training
        </h2>

        <div className="relative">
          {/* Centralna linija */}
          <div className="absolute h-full border-l-2 border-teal-400 left-1/2 transform -translate-x-1/2 z-0"></div>

          {educationData.map((item, index) => (
            <div
              key={index}
              className={`mb-14 flex ${
                index % 2 === 0 ? "justify-end" : "justify-start"
              } items-center w-full relative`}
            >
              <div
                className={`relative bg-white/5 backdrop-blur-lg border border-teal-400 text-white p-6 rounded-2xl shadow-xl w-full md:w-1/3 z-10
                ${index % 2 === 0 ? "ml-12" : "mr-12"}
                ${index % 2 === 0 ? "mr-auto" : "ml-auto"}
                text-center transition-all duration-300 hover:scale-105 hover:shadow-teal-500/40`}
              >
                <span className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-black text-teal-400 px-4 py-1 text-sm font-semibold rounded-full shadow-md border border-teal-400 z-20">
                  {item.year}
                </span>
                <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                <p className="text-gray-300 text-sm">{item.description}</p>
              </div>

              {/* Tačka na liniji */}
              <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 bg-teal-400 h-4 w-4 rounded-full z-20"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
