import { useState, useEffect } from "react";
import programer from "./programer.png"; // Uvezi svoju sliku

export const Home = () => {
  const [displayedText, setDisplayedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const roles = ["Web Developer", "UI / UX Designer"];

  useEffect(() => {
    const handleTyping = () => {
      const i = loopNum % roles.length;
      const fullText = roles[i];

      if (isDeleting) {
        setDisplayedText(fullText.substring(0, displayedText.length - 1));
        setTypingSpeed(50);
      } else {
        setDisplayedText(fullText.substring(0, displayedText.length + 1));
        setTypingSpeed(150);
      }

      if (!isDeleting && displayedText === fullText) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && displayedText === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    const timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, loopNum, roles, typingSpeed]);

  return (
<section id="home" className=" text-white z- min-h-[85vh] flex items-center pt-2 md:pt-16">
  <div className="container mx-auto px-6 py-8 flex flex-col md:flex-row items-center justify-between">
    {/* Tekstualni deo */}
    <div className="flex flex-col justify-center md:w-1/2 mt-12 md:mt-0">
      <h1 className="text-4xl md:text-6xl font-bold mb-4">
        Hi, It's <span className="text-teal-400">Milan Govedarica</span>
      </h1>
      <p className="text-lg md:text-4xl mb-6">
        I'm a <span className="text-teal-400">{displayedText}</span>
      </p>
      <p className="text-gray-400 mb-8">
        I'm Milan Govedarica, a passionate Web Developer and UI/UX Designer dedicated to crafting visually stunning and user-friendly digital experiences. With a keen eye for detail and a focus on clean, responsive design, I help businesses build modern websites and applications that not only look great but also provide an intuitive and seamless user experience. Let’s work together to bring your ideas to life!
      </p>
      <div className="flex space-x-4">
        {/* Dugme "Hire" sada vodi do sekcije sa id="contact" */}
        <a href="#contact" className="px-6 py-2 bg-teal-400 text-black font-bold rounded-full hover:bg-teal-300 transition-colors duration-300">Hire</a>
        <a href="#contact" className="px-6 py-2 border-2 border-teal-400 text-teal-400 font-bold rounded-full hover:bg-teal-400 hover:text-black transition-colors duration-300">Contact</a>
      </div>
    </div>
    {/* Slika sa efektom */}
    <div className="md:w-1/2 flex justify-center mt-8 md:mt-0">
      <div className="relative w-80 h-80 flex justify-center items-center group rounded-full overflow-hidden transition-all duration-500">
        {/* Osvetljenje */}
        <div className="absolute inset-0 rounded-full bg-teal-400 opacity-50 blur-lg group-hover:opacity-100 transition-opacity duration-500"></div>
        {/* Border sa isijavanjem na hover */}
        <div className="absolute inset-0 rounded-full bg-teal-500 border-4 border-teal-400 animate-pulse group-hover:border-teal-200 transition-colors duration-500 group-hover:shadow-[0_0_30px_10px_rgba(56,189,248,0.8)]"></div>
        {/* Slika sa efektom */}
        <img
          src={programer}
          alt="Milan Govedarica"
          className="rounded-full object-contain w-full h-full relative z-10 transition-transform duration-500 scale-105 group-hover:scale-110"
        />
      </div>
    </div>
  </div>
</section>



  );
};
