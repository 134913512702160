export const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 w-full z-50 bg-black/70 backdrop-blur-md shadow-inner">
      <div className="container mx-auto px-6 py-4 flex flex-col md:flex-row justify-between items-center">
        <p className="text-sm text-gray-300 text-center md:text-left mb-2 md:mb-0">
          © {new Date().getFullYear()} Milan Govedarica. All rights reserved.
        </p>

   
      </div>
    </footer>
  );
};
